/* You can add global styles to this file, and also import other style files */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0d152e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d152e;
}

.bg-primary-color {
  background-color: #0d152e !important;
}

.text-underline-none,
.btn-link:hover {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.br-12 {
  border-radius: 12px;
}

.br-5 {
  border-radius: 5px;
}

.font-bold {
  font-weight: bold;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.slick-slide {
  margin-right: 20px;
}

.rounded-circle {
  border-radius: 50%;
}


.text-three-line-restrict {
  color: var(--yt-spec-text-primary);
  line-height: 1.4rem;
  overflow: hidden;
  display: block;
  max-height: 4.4rem;
  -webkit-line-clamp: 3;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-four-line-restrict {
  color: var(--yt-spec-text-primary);
  line-height: 1.7rem;
  overflow: hidden;
  display: block;
  max-height: 7.6rem;
  -webkit-line-clamp: 4;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-five-line-restrict {
  color: var(--yt-spec-text-primary);
  line-height: 2rem;
  overflow: hidden;
  display: block;
  max-height: 10.8rem;
  -webkit-line-clamp: 5;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

body .btn-three a:after {
  margin-right: 10px;
}

.z-ind-1 {
  z-index: 1;
}

.chip {
  display: inline-block;
  height: 32px;
  padding: 0 12px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  /* color: rgba(0, 0, 0, 0.7); */
  /* cursor: pointer; */
  background-color: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  box-shadow: none;
}

.fw-bold {
  font-weight: bold;
}

.address ul li span a {
  color: #cbd0d4 !important;
}

@media screen and (max-width: 990px) {
  .mobile-column-reverse {
    flex-direction: column-reverse;
  }
}

.borders {
  box-shadow: inherit;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-tags {
  box-shadow: inherit;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  margin-right: 5px;
  border-radius: 8px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff !important;
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

@media screen and (max-width: 768px) {

  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

/* #accordionExample .card {
  background-color: #0d152e !important;
  color: #fff !important;
  box-shadow: inherit;
  border: 1px solid rgba(255, 255, 255, 0.1);
} */